/* ==============
  VARIABLES
===================*/

$clr-body-bkg:          #eceff4;

$clr-text-dark-primary:     rgba(0,0,0,0.87);
$clr-text-dark-secondary:   rgba(0,0,0,0.54);
$clr-text-dark-disabled:    rgba(0,0,0,0.38);
$clr-text-dark-divider:     rgba(0,0,0,0.12);
$clr-icon-dark-active:      rgba(0,0,0,0.50);
$clr-icon-dark-inactive:    rgba(0,0,0,0.38);

$clr-text-light-primary:    rgba(255,255,255,1.00);
$clr-text-light-secondary:  rgba(255,255,255,0.70);
$clr-text-light-disabled:   rgba(255,255,255,0.50);
$clr-text-light-divider:    rgba(255,255,255,0.12);
$clr-icon-light-active:     rgba(255,255,255,1.00);
$clr-icon-light-inactive:   rgba(255,255,255,0.50);

$clr-card-head: #38354a;
$clr-thead:     #2b2838;

/* ==============
  BOOTSTRAP VARIABLES
===================*/

//
// Color system
//
$white:    #fff;
$gray-100: #F5F5F5;
$gray-200: #EEEEEE;
$gray-300: #E0E0E0;
$gray-400: #BDBDBD;
$gray-500: #9E9E9E;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$black:    #000;

$grays: ();
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$blue:    #2196F3;
$indigo:  #3F51B5;
$purple:  #673AB7;
$pink:    #E91E63;
$red:     #F44336;
$orange:  #FF9800;
$yellow:  #FFEB3B;
$green:   #4CAF50;
$teal:    #009688;
$cyan:    #00BCD4;

$colors: ();
$colors: map-merge((
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
), $colors);

$primary:       $purple;
$secondary:     $gray-600;
$success:       $green;
$info:          $teal;
$warning:       $orange;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: ();
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
), $theme-colors);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  166;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $clr-text-dark-primary;
$yiq-text-light:            $clr-text-light-primary;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:    false;

// Body
//
// Settings for the `<body>` element.

$body-bg:       $clr-body-bkg;
$body-color:    $clr-text-dark-primary;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-headings:   "Montserrat", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$font-size-base: 0.85rem; // Assumes the browser default, typically `16px`
$font-size-lg:   1rem;
$font-size-sm:   .75rem;
$font-size-xs:   .65rem;

$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-medium: 500;

$headings-font-family:   $font-family-headings;
$headings-font-weight:   $font-weight-normal;

$text-muted: $clr-text-dark-secondary;

$hr-border-color: $clr-text-dark-divider;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            0.5rem;

$table-head-bg:                 $clr-thead;
$table-head-color:              $clr-text-light-primary;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                $font-weight-bold;

// Forms

$input-color:                    $clr-text-dark-primary;
$input-placeholder-color:        $clr-text-dark-secondary;
$input-group-addon-color:        $clr-icon-dark-active;

$form-group-margin-bottom:       0.5rem;

$custom-file-width:            100%;

$custom-file-color:         $input-color;
$custom-file-button-color:  $clr-text-light-primary;
$custom-file-button-bg:     $primary;

// Cards

$card-border-color:        $clr-text-dark-divider;

// Tooltips

$tooltip-bg:               $gray-700;

// Code

$kbd-color:                         $cyan;
$kbd-bg:                            $clr-card-head;
