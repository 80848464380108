pre {
    code {
        del {
            color: $red;
            text-decoration: none;
        }
        ins {
            color: $green;
            text-decoration: none;
        }
    }

}
