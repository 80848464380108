/* ==============
  Sidebar
===================*/
.sidebar {
    background: $clr-nav-side-base;
    box-shadow: $shadow-1;
    nav.nav {
        a.nav-link {
            display: block;
            padding: .75rem .45rem;
            color: $clr-text-light-primary;
            text-decoration: none;
            background: 0 0;
            font-weight: $font-weight-medium;
            font-family: $font-family-headings;
            border-left: 3px solid transparent;
            i {
                width: 20px;
                margin: 0 .3rem 0 0;
                font-size: 14px;
                color: $clr-icon-light-inactive;
                text-align: center;
                display: inline-block;
                margin-top: -4px;
                vertical-align: middle;
            }
            &.active, &:hover.nav-link {
                color: $clr-text-light-primary;
                background: $clr-nav-side-accent;
                i {
                    color: $clr-icon-light-active;
                }
            }
            &.active {
                border-color: $primary;
            }
            .user-profile-link-custom i {
                font-size: 15px !important;
                color: #000 !important;
                margin-right: 0px;
            }
        }
        a.nav-link.back-to-reports {
            background-color: $clr-text-light-divider;
            cursor: pointer;
            i {
                font-size: x-large;
            }
            &:hover.nav-link.back-to-reports {
                color: $clr-icon-light-active;
                background-color: $clr-text-light-divider;
                i {
                    color: $clr-icon-light-active;
                }
            }
        }
    }
    .small-icon i{
      font-size: small !important;
      color: white !important;
    }
    .btn {
        height: 100%;
        i {
            transition: $btn-transition;
        }
        &.btn-dark {
            i {
                color: $clr-icon-light-inactive;
            }
        }
        &:hover {
            &.btn-dark {
                i {
                    color: $clr-icon-light-active;
                }
            }
        }
    }
    .entity-list {
        overflow-y: auto;
        height: calc(100vh - 83px);
        display:list-item !important;
    }
    &.expand-sidebar {
        width: 180px !important;
        text-overflow: unset !important;
        overflow: visible !important;
        .search-entity {
            width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .entity-list {
            height: calc(100vh - 83px);
        }
    }
    .entity-scroll {
        overflow: visible !important;
    }
    .input-group-search {
        box-shadow: $shadow-1;
        .form-control {
            height: 35px;
        }
        .input-group-prepend {
            height: 35px;
            width: 100%;
            .input-group-text {
                display: block;
                width: 100%;
                background-color: $primary;
                color: $clr-icon-light-inactive;
                border: none;
                cursor: pointer;
                transition: $btn-transition;
                &:hover {
                    color: $clr-icon-light-active;
                }
                i {
                    margin: .4rem 0 0 0;
                }
            }
        }
        &.active {
            .input-group-prepend {
                width: unset;
                .input-group-text {
                    display: flex;
                    background-color: $primary;
                    color: $clr-icon-light-active;
                    &:hover {
                        color: $clr-icon-light-inactive;
                    }
                    i {
                        margin: 0;
                    }
                }
            }
        }
    }
}
con-entities-layout, con-task-manager-layout {
    .sidebar {
        nav.nav {
            a.nav-link {
                text-align: center;
                padding: .75rem 1rem;
                i {
                    margin: 0 2px 0 0;
                }
            }
        }
        &.expand-sidebar {
            nav.nav {
                a.nav-link {
                    padding: .75rem 1rem .75rem .35rem;
                    text-align: left;
                }
            }
        }
    }
}

/* ==============
  Header
===================*/
.header {
    background: $clr-nav-top-base;
    box-shadow: $shadow-1;
}
/* ==============
  Header Left
===================*/
.logo{
    font-weight: $font-weight-medium;
    font-family: $font-family-headings;
    line-height: 1;
    font-size: $font-size-lg * 1.6;
    letter-spacing: -0.5px;
    i {
        color: $primary;
    }
}

/* ==============
  Header Right
===================*/
.header-right {
    .nav {
        con-toolbar {
            display: flex;
            .nav-item {
                .nav-link {
                    border-top: 2px solid transparent;
                    i {
                        color: $clr-icon-dark-inactive;
                        transition: $btn-transition;
                    }
                    &.active {
                        background-color: $clr-nav-top-accent;
                        border-top: 2px solid $primary;
                        i {
                            color: $primary;
                        }
                        &:hover {
                            i {
                                color: $primary;
                            }
                        }
                    }
                    &.active:hover {
                        background-color: $clr-nav-top-accent;
                    }
                    &:hover {
                        background-color: lighten($clr-nav-top-accent, 2.5%);
                        i {
                            color: $clr-icon-dark-active;
                        }
                    }
                }
            }
        }
        .profile {
            .nav-link {
                img {
                    margin-right: $spacer * .25;
                }
                &:hover {
                    background-color: $clr-nav-top-base;
                }
                &.logout {
                    cursor: pointer;
                    background-color: $clr-nav-top-accent;
                    transition: all 150ms ease-in-out;
                    i {
                        margin: 0;
                        color: $clr-icon-dark-inactive;
                        transition: all 150ms ease-in-out;
                    }
                    &:hover {
                        background-color: $red;
                        transition: all 150ms ease-in-out;
                        i {
                            color: $clr-icon-light-active;
                            transition: all 150ms ease-in-out;
                        }
                    }
                }
                &.settings {
                    &:hover {
                        background-color: lighten($clr-nav-top-accent, 2.5%);
                    }
                    &.active {
                        background-color: $primary;
                        i {
                            color: $clr-icon-light-active;
                        }
                    }
                }
            }
        }
    }
}
