/* ==============
  PROGRESS LOADER
===================*/
.progress-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1080;
  height: 2px;
  display: block;
  width: 100%;
  background-color: $clr-progress-loader-base;
  overflow: hidden;
    .indeterminate {
      background-color: $clr-progress-loader-accent;
    }
    .indeterminate:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }
    .indeterminate:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 1.15s;
    }
}


// progress animation
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

/* ==============
  CIRCULAR LOADER
===================*/
.circular-loader-container {
    display: flex;
    justify-content: center;
    margin: $spacer;
    .circular-loader {
        position: relative;
        margin: 0 auto;
        width: $width-circular-loader;
        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }
        &.small {
            width: $width-circular-loader-small;
        }
        .circular {
            animation: rotate 2s linear infinite;
            height: 100%;
            transform-origin: center center;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
        .path {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
            stroke-linecap: round;
        }
    }
}

// custom elements
.header {
  .circular-loader-container {
    margin: 0;
    .circular-loader {
      width: $width-circular-loader-header;
    }
  }
}

.alert {
  .circular-loader-container {
    margin: 0;
  }
}

.login-container {
  .circular-loader-container {
    margin: 0;
    .circular-loader {
      width: $width-circular-loader-login;
    }
  }
}

.btn {
  .circular-loader-container {
    margin: 0;
    .circular-loader {
      width: $width-circular-loader-btn;
    }
  }
}

.input-group-text {
  .circular-loader-container {
    margin: 0;
    .circular-loader {
      width: $width-circular-loader-input-group;
    }
  }
}

td, th {
  .circular-loader-container {
    margin: 0 auto;
    .circular-loader {
      width: $width-circular-loader-header;
    }
  }
}

// circular animation
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $clr-circular-loader-1;
  }
  40% {
    stroke: $clr-circular-loader-2;
  }
  66% {
    stroke: $clr-circular-loader-3;
  }
  80%,
  90% {
    stroke: $clr-circular-loader-4;
  }
}