@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i|Montserrat:400,500,400i,500i');
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import '~highlight.js/styles/vs2015.css';
@import 'variables-bootstrap';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'variables-project';
@import 'bootstrap-customization';
@import 'layout';
@import 'loaders';
@import 'navigation';
@import 'elements';

@import 'markup';
@import 'drag-n-drop';
@import 'ng-select';

@import "~jsoneditor/dist/jsoneditor.min.css";
@import '../node_modules/ngx-toastr/toastr-bs4-alert.scss';

@import "../node_modules/flag-icons/css/flag-icons.min.css";

@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/blackboard.css';
@import '~codemirror/addon/fold/foldgutter.css';
@import '~codemirror/addon/lint/lint.css';
