/* ==============
  PROJECT VARIABLES
===================*/

//LOADERS
$clr-progress-loader-base:      rgba(103,58,183,0.15);
$clr-progress-loader-accent:    rgba(103,58,183,1.0);

$clr-circular-loader-1:     #D500F9;
$clr-circular-loader-2:     #9C27B0;
$clr-circular-loader-3:     #651FFF;
$clr-circular-loader-4:     #673AB7;

$width-circular-loader:                 50px;
$width-circular-loader-header:          32px;
$width-circular-loader-login:           34px;
$width-circular-loader-btn:             13px;
$width-circular-loader-input-group:     20px;
$width-circular-loader-small:           35px;

//NAVIGATION
$clr-nav-side-base:     #38354a;
$clr-nav-side-accent:   #2b2838;
$clr-nav-top-base:      #fafafa;
$clr-nav-top-accent:    #eceff4;

//MISC
$shadow-1:    0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
$shadow-2:    0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
$shadow-3:    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
$shadow-4:    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
$shadow-5:    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
$shadow-6:    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
$shadow-7:    0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);